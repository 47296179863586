@import './_styles/variables.scss';
@import './_styles/scrollbars.scss';
@import './_styles/markdown-frame.scss';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-title-section {
  height: 48px;
  min-height: 48px;
  border-bottom: 1px solid rgb(237, 235, 233);
}

.cancel-button {
	background: $lightestGrey;
	border-radius: 2px;
  border: none;

	&:hover {
		background: $hoverGrey;
	}
}

.company-logo-large {
  max-height: 120px;
  min-height: 120px;
}

.company-logo-small {
  max-height: 20px;
}

.ms-DetailsList-headerWrapper {
  top: 0;
  position: sticky;
  z-index: 10;
}

.notification-circle {
  display: flex;
  background-color: $ms-blue;
  color: white;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  min-width: 14px;
  min-height: 14px;
  margin-right: 6px;
  span {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: -4px
  }
}

.noselect {
	cursor: default !important;
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
