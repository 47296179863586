@import '../../../_styles/variables.scss';

.ms-Viewport {
    height: 100%;
    .ms-DetailsList.reports {
        .ms-DetailsRow {
            height: 40px;
        }
    }
    .ms-DetailsList {
        height: 100%;
        .ms-DetailsHeader {
            padding-top: 0;
        }

        .ms-DetailsRow {
            //background-color: transparent;
            .ms-DetailsRow-cell {
                display: flex;
                align-items: center;
            }
    
            &.is-selected {
                background: $selectedGrey;
            }
    
            &:focus:after {
                border: none;
            }
        }

        .ms-DetailsHeader-cellSizer {
            cursor: col-resize !important;
        }

        .ms-DetailsHeader,
        .ms-DetailsRow,
        .ms-GroupHeader,
        .ms-DetailsHeader-cell {
            &:hover {
                background-color: $hoverGrey;
            }
        }
    }
}