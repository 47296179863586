@import '../../_styles/variables.scss';

.Sidebar {
    height: 100%;
    background-color: $lightestGrey;
    display: flex;
    flex-direction: column;

    .fui-Tab {
        &::after, &::before {
            margin-left: 4px;
        }
    }


    .hover-button {
        background-color: transparent;
        border: none;
        &:hover {
            background-color: $hoverGrey !important;
            span {
                background-color: $hoverGrey !important;
                color: $ms-blue;
                i {
                    color:rgb(50, 49, 48);
                }
            }
        }
    }
}