@import '../../_styles/variables.scss';

.modal-wrapper {
	background-color: #faf9f8;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 40px;
	height: calc(100% - 40px);

	.title {
		vertical-align: sub;
	}

	.lead {
		font-weight: 600;
	}

	.form-modal {
		max-width: 480px;
		width: 90%;
	}

	.scrollable-modal-content {
		max-height: 100%;
		overflow: hidden;
	}

	.ms-TextField-fieldGroup::before {
		right: 0;
	}

	.required-hidden {
		.ms-TextField-fieldGroup::before {
			display: none
		}
	}
}