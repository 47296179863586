@import '../../_styles/variables.scss';

.Titlebar {
	border-top: 1px solid grey;
	background: $darkGrey;
	height: 40px;
	//padding: 10px 12px;

	.title {

		img {
			vertical-align: text-top;
			width: 18px;
		}

		span, button, button:hover {
			color: white;
			font-size: 14px;
			font-weight: 600;
			text-decoration: none;
		}
	}

	.btn-openInDesktop {
		background-color: transparent;
		border: 1px solid white;
		border-radius: 3px;
		color: white;
		font-size: 10px;
		height: 24px;
		//right: 35px;
		margin-right: 1rem;

		&:hover {
			background-color: white;
			color: $darkGrey;
		}
	}

	.header-waffle {
		width: 48px;
		height: 39px;
		border-radius: 0;
		position: relative;
		&:hover {
			background-color: $lightGrey;
		}
	}

	.language-button {
		color: white !important;
		.fui-Button__icon, .fui-Icon-regular, .fui-Icon-filled {
			color: white;
			font-weight: 400 !important;
		}
		svg {
			vertical-align: baseline;
		}
	}
}