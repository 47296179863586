@import '../../_styles/variables.scss';

.messages-box {
	//min-height: 300px;
	//max-height: 300px;
	//max-width: 800px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.message-wrapper {
	padding: 10px 16px;
	position: relative;
	
	.message {
		background-color: white;
		border: 1px solid $lightGrey;
		border-radius: 6px;
		z-index: 2;

		.message-top {
			background-color: $lightestGrey;
			border-bottom: 1px solid $lightGrey;
			border-top-right-radius: 6px;
			border-top-left-radius: 6px;
			padding: 6px;
			z-index: 3;
		}

		.message-content {
			padding: 0 12px 12px 12px;

			.to, .cc {
				font-size: 12px;
				margin-top: 6px;
			}
		}

		.message-bottom {
			//border-top: 1px solid $lightestGrey;
			font-size: 12px;

			button {
				height: 30px;
			}
		}
	}
}

.attachment {
	height: 32px;
	display: flex;
	align-items: center;
	border: 1px solid $lightGrey;
	margin: 8px 0px 0px 0px;
	padding: 2px 10px;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:hover {
		cursor: default;
	}
}

.normal-button-opacity {
	&:hover {
		background-color: #e9e9e9aa;
	}
}