@import '../../../_styles/variables.scss';

.dashboard-card {
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
	transition-property: box-shadow;
	transition-duration: 200ms;
	min-height: 100px;
	min-width: 150px;
	//max-width: 200px;
	//width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	flex: 1 1 0;

	&:hover {
		&::after {
			border: none;
		}
		border-color: $lightGrey;
		box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
	}
}

.dashboard-number {
	font-size: 28px;
	text-align: center;
	margin: 20px auto;
	height: 48px;
	width: 48px;
	display: flex;
	border-radius: 24px;

	span {
		vertical-align: middle;
		margin: auto;
		padding-bottom: 4px;
	}

	&.darkred-circle {
		background-color: #d69ca5;
		color: #420610;
	}
	&.red-circle {
		background-color: #f4bfab;
		color: #7a2101;
	}
	&.orange-circle {
		background-color: #f9e2ae;
		color: #835b00;
	}
	&.yellow-circle {
		background-color: #fef7b2;
		color: #817400;
	}
	&.green-circle {
		background-color: #bdd99b;
		color: #294903;
	}
}