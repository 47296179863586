@import '../../_styles/variables.scss';

.Header {
    width: 100%;
    height: 96px;
    background-color: $lightestGrey;
    border-bottom: 1px solid $lightGrey;

    .history-actions {
		margin-left: 8px;

		button {
            margin-top: 5px;
			height: 34px;
			width: 34px;
            border: 1px solid $lightestGrey;

			&:not(.is-disabled) {
				i { color: $darkGrey; }

                &:hover {
                    background-color: $hoverGrey;
                    border-color: #E5E5E5;
                }
			}
		}
	}

	.refresh-action {
		margin-left: 8px;

		button {
			height: 34px;
            margin-top: 5px;
            border: 1px solid $lightestGrey;

			i {
				font-size: 1.1em;
				margin-top: 1px;
			}

			&:not(.is-disabled) {
				i { color: $darkGrey; }

                &:hover {
                    background-color: $hoverGrey;
                    border-color: #E5E5E5;
                }
			}
		}
		.ms-Spinner-wrap {
			//margin-top: 10px;
			margin: 14px 4px 0 4px;
			width: 24px;

			.ms-Spinner {
				.ms-Spinner-circle {
					border-color: $lightGrey $grey $grey;
				}
			}
		}
	}

    .breadcrumb {
		background: $lightestGrey;
		border: 1px solid $lightGrey;
		height: 34px;
		margin-left: 8px;
		margin-top: 5px;
		max-width: 100%;

		.breadcrumb-icon {
			color: $darkGrey;
			font-size: 1.4em;
			line-height: 32px;
			vertical-align: middle;
            display: flex;
            align-items: center;
            height: 100%;
		}

		.ms-Breadcrumb {
			margin: 0;

			.ms-Breadcrumb-itemLink {
				border: none;
				&:hover {
					background: $hoverGrey;
				}
			}

			span, button, a, i {
				color: $darkGrey;
				line-height: 32px;
			}
			span, button, a {
				font-size: 14px;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

    .sidepanel-button {
        margin-left: 4px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        border: 1px solid $lightestGrey;

        &:hover {
            background-color: $hoverGrey;
            border-color: #E5E5E5;
        }

        i {
            color: $darkGrey;
            font-size: 20px;
        }
    }

	.toolbar-button {
		background: $lightestGrey;
		height: 32px;
		margin-top: 4px;
		border-radius: 2px;
		border: 1px solid $lightestGrey;

		&:hover {
			background: $hoverGrey;
			border: 1px solid #E5E5E5;
		}

		svg {
			vertical-align: baseline;
		}
	}
}