@import '../../_styles/variables.scss';

.DetailsPanel {
    height: 100%;
    background-color: $lightestGrey;
    display: flex;
    flex-direction: column;

    .fui-Tab {
        &::after, &::before {
            margin-left: 4px;
        }
    }

    .lead {
		font-size: 14px;
        font-weight: 700;
		word-break: break-word;
	}
}

ul {
    li {
        list-style: none;
    }
}

.text-secondary  {
    font-size: 12px;
}

.fui-Option__checkIcon {
    min-width: 16px !important;
}