@import '../../../_styles/variables.scss';

.attachment-area {
    display: flex;
    flex-direction: column;
    border: 1px dashed #d1d1d1;
    border-radius: 4px;
    min-height: 130px;

    .file-selector {
        color: $highlight;
        cursor: pointer;
    }
}
.form-text {
    .fui-Textarea__textarea {
        width: 100%;
        min-height: 270px;
    }
}
