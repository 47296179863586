$brandColor: #455366;

$themePrimary: #435466;
$themeSecondary: #69afe5;
$themeLight: #C7E0F4;
$highlight: rgb(0, 120, 212);
$greyHover: rgb(248, 248, 248);

$grey: #666;
$greyText: #999;
$lightGrey: #bfbfbf;
$lightGreyW: #a4b2c2;
$midGrey: #7F7F7F;
$lightestGrey: #f2f2f2;
$lightestGreyW: #f4f6fa;
$ultraLightGrey: #faf9f8;
$darkGrey: #2c2c2c;

$ms-blue: rgb(16, 110, 190);
$ms-lightGrey: rgb(237, 235, 233);
$ms-darkGrey: rgb(50, 49, 48);
$marker: #FFFF99; // Post-it color
$green: #00FF00;

$statusGrey: #707070;
$statusYellow: #f9d804;
$statusGreen: #00cd21;
$statusRed: #f50404;

$selectedGrey: #e1e1e1;
$hoverGrey: #e9e9e9;