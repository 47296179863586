::-webkit-scrollbar {
	border-radius: 100px;
	-webkit-border-radius: 100px;
	background-color: transparent;
	height: 12px;
	width: 12px;
}

::-webkit-scrollbar-thumb:horizontal {
	background: #bfbfbf;
    border-radius: 100px;
	-webkit-border-radius: 100px;
	&:hover {
		background: #999999;
	}
}

::-webkit-scrollbar-thumb:vertical {
    background: #bfbfbf;
    border-radius: 100px;
	-webkit-border-radius: 100px;
	&:hover {
		background: #999999;
	}
}
::-webkit-scrollbar-thumb:vertical:active {
    background: #999999;
    border-radius: 100px;
	-webkit-border-radius: 100px;
}

section.page:not(.focus):not(:hover)::-webkit-scrollbar {
	display: none;
}
::-webkit-scrollbar-track,::-webkit-scrollbar-thumb {
	border: 5px solid transparent;
	border-radius: 999px;
}
::-webkit-scrollbar-thumb {
	background: #bfbfbf;
	background-clip: content-box;
	min-height: 20px;
}
::-webkit-scrollbar-corner {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	border: 2px solid rgb(250, 249, 248);
}